.singlePost {
    flex: 9;
}

.singlePostWrapper {
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.singlePostImg {
    width: 100%;
    height: 300px;
    border-radius: 5px;
    object-fit: cover;
}

.singlePostTitle {
    color: hsl(0, 0%, 13%);
    text-align: center;
    margin: 10px;
    font-family: "Lora", serif;
    font-size: 20px;
}

.singlePostTitleInput {
    text-align: center;
    margin: 10px;
    font-family: "Lora", serif;
    font-size: 20px;
    border: none;
    color: grey;
    border-bottom: 1px solid lightgray;
}

.singlePostTitleInput:focus {
    outline: none;
}

.singlePostEdit {
    float: right;
    font-size: 16px;
}

.singlePostIcon {
    margin-left: 10px;
    cursor: pointer;
}

.singlePostIcon:first-child {
    color: teal;
}

.singlePostIcon:last-child {
    color: tomato;
}

.singlePostInfo {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-family: "Varela Round", "Nunito", sans-serif;
    color: #be9656;
}

.singlePostDesc {
    color: hsl(0, 0%, 29%);
    font-size: 18px;
    line-height: 25px;
    white-space: pre-line;
    font-family: "Varela Round", "Nunito", sans-serif;
    text-align: justify;
}

.singlePostDesc img {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.singlePostDesc::first-letter {
    margin-left: 20px;
    font-size: 30px;
    font-weight: 600;
}

.singlePostDescInput {
    border: none;
    color: #666;
    font-size: 18px;
    line-height: 25px;
    height: 100vh;
}

.singlePostDescInput:focus {
    outline: none;
}

.singlePostButton {
    width: 100px;
    border: none;
    background-color: teal;
    padding: 5px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    align-self: flex-end;
    margin-top: 20px;
}

.singlePostShareButtons {
    margin-bottom: 20px;
    text-align: center;
}

.singlePostShareButton {
    margin-right: 5px;
}

@media (min-width: 1024px) {
    .singlePostWrapper {
        padding-right: 0;
    }

    .singlePostImg {
        height: 600px;
        border-radius: 10px;
    }
}
