.top{
    width: 100%;
    height: 50px;
    background-color: white;
    color: hsl(0, 0%, 13%);
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    font-family: 'Josefin Sans', 'Montserrat Alternates', sans-serif;
    z-index: 999;
    overflow: auto;
}

.topLeft,.topRight{
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.topCenter{
    flex: 6;
    white-space: nowrap;
}

.topIcon{
    font-size: 20px;
    margin-right: 10px;
    color: hsl(0, 0%, 29%);
    cursor: pointer;
}

.topImg{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
}

.topList{
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
}

.topListItem{
    margin-right: 20px;
    font-size: 18px;
    font-weight: 300;
    cursor: pointer;
}

.topSearchIcon{
    font-size: 18px;
    color: hsl(0, 0%, 29%);
    cursor: pointer;
    margin-left: 15px;
}

.burger{
    position: absolute;
    width: 20px;
    height: 17px;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    display: none;
}

.burgerLine{
    width: 100%;
    height: 3px;
    background-color: hsl(0, 0%, 29%);
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
}

.responsiveMenu{
    position: fixed;
    margin-top: 20px;
    top: 0;
    left: 0;
    height: 502px;
    /* height: calc(100vh - 70px); */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: left;
    font-size: 22px;
    font-family: 'Josefin Sans', 'Montserrat Alternates', sans-serif;
    z-index: 1;
}

.responsiveLink{
    margin-top: 30px;
    margin-left: 30px;
    text-decoration: none;
    color: hsl(0, 0%, 29%);
    cursor: pointer;
}

.responsiveLink:first-child{
    margin-top: 80px;
}

.responsiveLink:last-child{
    margin-top: 60px;
}

@media screen and (max-width:728px){
    .top .topListItem {
        justify-content: flex-end;
        display: none;
    }
    .topRight .topImg {
        display: none;
    }
    .burger {
        display: flex;
    }
}