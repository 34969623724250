.sidebar{
    flex: 3;
    margin: 20px;
    padding-bottom: 30px;
    color: hsl(0, 0%, 29%);
    background-color: #fdfbfb;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebarItem{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
}

.sidebarTitle{
    margin: 10px;
    padding: 5px;
    width: 80%;
    border-top: 1px solid #a7a4a4;
    border-bottom: 1px solid #a7a4a4;
    font-family: 'Varela Round', 'Nunito', sans-serif;
    font-size: 12px;
    color: hsl(0, 0%, 13%);
    font-weight: 600;
    line-height: 20px;
    text-align: center;
}

.sidebarItem > img{
    width: 80%;
    margin-top: 15px;
}

.sidebarItem > p{
    padding: 30px;
    font-family: 'Varela Round', 'Nunito', sans-serif;
    white-space: pre-line;
}

.sidebarList{
    list-style: none;
    margin-bottom: 30px;
}

.sidebarListItem{
    display: inline-block;
    width: 50%;
    margin-top: 15px;
    cursor: pointer;
}

.sidebarSocial{
    margin-top: 15px;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebarIcon{
    font-style: 16px;
    margin-left: 10px;
    color: hsl(0, 0%, 29%);
    cursor: pointer;
}