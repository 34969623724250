.header {
    margin-top: 20px;
}

.headerTitles {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Bellota", serif;
    color: hsl(0, 0%, 13%, 0.86);
}

.headerTitleSm {
    position: absolute;
    top: 10%;
    font-size: 60px;
}

.headerTitleLg {
    position: absolute;
    top: 100px;
    font-size: 50px;
}

.headerImg {
    width: 100%;
    height: 450px;
    /* margin-top: 80px; */
    object-fit: cover;
}

@media (min-width: 1024px) {
    .headerTitleLg {
        top: 60px;
        font-size: 160px;
    }
}
