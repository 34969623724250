.post {
    flex-basis: 385px;
    flex-grow: 1;
    margin: 0px 20px 40px 20px;
}

.postImg {
    width: 100%;
    height: 280px;
    object-fit: cover;
    border-radius: 7px;
}

.postInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.postHashtags {
    text-overflow: clip;
    overflow: hidden;
    width: 265px;
    white-space: nowrap;
    text-align: center;
}

.postHashtag {
    font-family: "Varela Round", "Nunito", sans-serif;
    font-size: 11px;
    color: #be9656;
    line-height: 20px;
    margin-top: 15px;
    margin-right: 10px;
    cursor: pointer;
}

.postTitle {
    color: hsl(0, 0%, 13%);
    font-family: "Josefin Sans", "Montserrat Alternates", sans-serif;
    font-size: 24px;
    font-weight: 700;
    margin-top: 15px;
    cursor: pointer;
}

.postAdditionalInfo {
    font-family: "Lora", serif;
    font-style: italic;
    font-size: 13px;
    color: hsl(0, 0%, 45%);
    margin-top: 15px;
    display: flex;
    width: 100%;
    align-items: center;
}

.postEyeIcon {
    flex: 6;
    text-align: left;
    margin-left: 30px;
}

.postEyeViewers {
    font-weight: normal;
}

.postDate {
    flex: 6;
    text-align: right;
    margin-right: 30px;
}

.postDesc {
    font-family: "Varela Round", "Nunito", sans-serif;
    font-size: 14px;
    color: hsl(0, 0%, 29%);
    line-height: 24px;
    margin-top: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-align: justify;
}

@media (min-width: 1024px){
    .post {
        margin: 0px 25px 40px 25px;
    }
}